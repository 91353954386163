import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './i18n';

Vue.use(Vuex);

const lsLang = localStorage.getItem('lang');
const systemLang = ['zh_CN', 'en_US', 'ja_JP'].includes(lsLang) ? lsLang : 'en_US'; // 目前只开放 简体中文、英文、日文 三种语言,

i18n.locale = systemLang;

const recordTypeFun = () => {
  return [
    {
      value: 0,
      label: i18n.t('uc.finance.record.charge'), // 充值
    },
    {
      value: 1,
      label: i18n.t('uc.finance.record.handrecharge'), // 人工充值
    },
    {
      value: 2,
      label: i18n.t('uc.finance.record.pickup'), // 提现
    },
    {
      value: 3,
      label: i18n.t('uc.finance.record.pickupfee'), // 提现手续费
    },
    {
      value: 4,
      label: i18n.t('uc.finance.record.pickupCode'), // 提现码提现
    },
    {
      value: 5,
      label: i18n.t('uc.finance.record.pickupCodePay'), // 提现码充值
    },
    {
      value: 9,
      label: i18n.t('uc.finance.record.activityaward'), // 活动奖励
    },
    {
      value: 10,
      label: i18n.t('uc.finance.record.promotionawardRebates'), // 推广返佣奖励
    },
    {
      value: 11,
      label: i18n.t('uc.finance.record.istantRedemptionRevenue'), // 闪兑收入
    },
    {
      value: 12,
      label: i18n.t('uc.finance.record.flashPayouts'), // 闪兑支出
    },
    {
      value: 13,
      label: i18n.t('uc.finance.record.instantRedemptionFees'), // 闪兑手续费
    },
    {
      value: 14,
      label: i18n.t('uc.finance.record.exchange'), // 币币交易
    },
    {
      value: 15,
      label: i18n.t('uc.finance.record.exchangefee'), // 币币手续费
    },
    {
      value: 16,
      label: i18n.t('uc.finance.record.contractfee'), // 永续合约手续费
    },
    {
      value: 17,
      label: i18n.t('uc.finance.record.contractprofit'), // 永续合约盈利
    },
    {
      value: 18,
      label: i18n.t('uc.finance.record.contractloss'), // 永续合约亏损
    },
    {
      value: 19,
      label: i18n.t('uc.finance.record.expenditureofcapitalexpenses'), // 支出资金费用
    },
    {
      value: 20,
      label: i18n.t('uc.finance.record.accesstofundingfees'), // 获得资金费用
    },
    {
      value: 24,
      label: i18n.t('uc.finance.record.secondfail'), // 秒合约失败
    },
    {
      value: 25,
      label: i18n.t('uc.finance.record.secondreward'), // 秒合约奖金
    },
    {
      value: 26,
      label: i18n.t('uc.finance.record.activityrevenue'), // 活动收入
    },
    {
      value: 27,
      label: i18n.t('uc.finance.record.activityexpenditure'), // 活动支出
    },
    {
      value: 29,
      label: i18n.t('uc.finance.record.redenvelopecollection'), // 红包领取
    },
    {
      value: 31,
      label: i18n.t('uc.finance.record.dividend'), // 分红
    },
    {
      value: 32,
      label: i18n.t('uc.finance.record.rebateincome'), // 返佣收入
    },
    {
      value: 45,
      label: i18n.t('uc.finance.record.adjust'), // 调整
    },
    {
      value: 50,
      label: i18n.t('uc.finance.record.secondfee'), // 秒合约手续费
    },
    {
      value: 51,
      label: i18n.t('uc.finance.record.secondcom'), // 秒合约市场调节费
    },
    {
      value: 55,
      label: i18n.t('uc.finance.record.coinTradeFeeDiscount'), // 币币手续费抵扣
    },
    {
      value: 56,
      label: i18n.t('uc.finance.record.contracttradeFeeDiscount'), // 合约手续费抵扣
    },
    {
      value: 57,
      label: i18n.t('uc.finance.record.redEnvelopeReturn'), // 红包退回
    },
    {
      value: 60,
      label: i18n.t('uc.finance.record.tradingRewards'), // 交易奖励
    },
    {
      value: 61,
      label: i18n.t('uc.finance.record.experienceUse'), // 合约体验金使用
    },
    {
      value: 62,
      label: i18n.t('uc.finance.record.deduction'), // 合约体验金抵扣
    },
    {
      value: 63,
      label: i18n.t('uc.finance.record.transfer'), // 划转
    },
    {
      value: 64,
      label: i18n.t('uc.finance.record.kolCommission'), // 划转
    },
  ];
};

export default new Vuex.Store({
  state: {
    member: null, // 用户登录个人信息
    lang: systemLang,
    exchangeSkin: 'night',
    loginTimes: null,
    currency: localStorage.getItem('currency') || 'USDT',
    // 资产流水类型
    recordType: recordTypeFun(),
  },
  mutations: {
    setMember(state, member) {
      state.member = member;
      localStorage.setItem('MEMBER', JSON.stringify(member));
      localStorage.setItem('H5_UZX_USERINFO', JSON.stringify(member)); // h5个人信息
    },
    recoveryMember(state) {
      state.member = JSON.parse(localStorage.getItem('MEMBER'));
    },
    // 改变系统语言
    setLang(state, lang) {
      state.lang = lang;
      i18n.locale = lang;
      state.recordType = recordTypeFun();

      localStorage.setItem('lang', lang); // 之前是写入到 LANGUAGE 字段内，现在和 h5 统一使用 lang 字段
      if (window['h5:languageChange']) {
        // 触发h5语言切换
        window['h5:languageChange'](lang);
      }
    },
    setCurrency(state, currency) {
      state.currency = currency;
      localStorage.setItem('currency', currency);
    },
    setRecordType(state, recordType) {
      state.recordType = recordType;
    },
    initLoginTimes(state) {
      if (localStorage.getItem('LOGINTIMES') == null) {
        state.loginTimes = 0;
      } else {
        state.loginTimes = JSON.parse(localStorage.getItem('LOGINTIMES'));
      }
    },
    setLoginTimes(state, times) {
      state.loginTimes = times;
      localStorage.setItem('LOGINTIMES', JSON.stringify(times));
    },
    setSkin(state, skin) {
      state.exchangeSkin = skin;
    },
  },
  getters: {
    member(state) {
      return state.member;
    },
    isLogin(state) {
      return state.member != null;
    },
    lang(state) {
      return state.lang;
    },
    currency(state) {
      return state.currency;
    },
    loginTimes(state) {
      return state.loginTimes;
    },
    recordType(state) {
      return state.recordType;
    },
  },
});
